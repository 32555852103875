<template>
  <j-overlay 
    v-model="opened" 
    content-right
    class="service_board"
    style="z-index: 6;"
    :id="localId"
  >
    <div class="j_modal_slide__content_wrapper" :class="classWrapper">
      <div class="lnb">
      </div>
      <div class="content">
        <div class="j_button__wrapper_top">
          <j-button v-if="modeView" class="type01 sky" text @click="onEdit" :class="{ disabled: !isOwner }">Edit</j-button>
          <j-button v-if="modeMod||modeNew" class="type01 sky" text @click="onSave">Save</j-button>
          <j-button v-if="modeView||modeMod" class="type01 delete" @click="onRemove" :class="{ disabled: !isOwner }">Delete</j-button>
          <j-button class="type01" text @click="onClose">{{ modeView ? 'Close' : 'Cancel' }}</j-button>
        </div>

        <div>
          <div class="tab-header">
            <span class="tab-title">Tortue Weekly Report Service</span>
            <span class="tab-sub-title">Requested by Peter Kim, 10-May 2020</span>
          </div>

          <div class="section">
            <div class="item_info">
              <span class="item_label">Title</span>
              <span v-if="modeView" class="item_value read w100">{{ item.title }}</span>
              <input v-else v-model="item.title" type="text" :class="`item_value main w100${errorClass('title')}`" />
            </div>
          </div>
          <div class="section">
            <div class="item_info">
              <span class="item_label">Category</span>
              <span v-if="modeView" class="item_value read w40">{{ getReportCatName(item.catcode, item.groupcode) }}</span>
              <select v-else v-model="item.groupcode" class="item_value w40">
                <option v-for="option in reportCatOptions[catCode]" :key="option.index" :value="option.value">{{ option.text }}</option>
              </select>
              <span style="width: 5rem;"></span>
              <span class="item_label">Cutoff</span>
              <span v-if="modeView" class="item_value read w40">{{ cutoff }}</span>
              <div v-else class="item_value w30" style="padding: 0 0;">
                <j-date-picker
                  v-model="cutoff"
                  no-border
                  no-default
                  class="item_value"
                  style="margin-top: 0;"
                  :nudge-left="7"
                />
              </div>
            </div>
          </div>

          <div class="section" style="margin-top: 4rem;">
            <div v-if="modeView" v-html="item.content" class="text_area"></div>
            <j-editor
              v-else
              ref="editor"
              allow-no-comment
              :accept="'.png, .jpg, .jpeg, .pdf'"
              :buttons="[true, false, false]"
              :comment="comment"
              :files="item.files"
              :limit-count="2"
              :notice-ext="'1 image, 1 report'"
              :progress="progress" 
              :thumb-size="200"
              @remove="onRemoveFile"
              @save="onSaveComment"
            />
          </div>

          <div v-if="modeView" class="section">
            <div v-if="fileReport" class="report_file_wrapper">
              <span class="report_file_name">{{ fileReport.name }}</span>
              <a v-ripple class="btn-download" @click="onDownload(fileReport)">
                <i class="mdi mdi-download"></i>
              </a>
            </div>
            <div v-if="fileImage" class="image_wrapper">
              <img class="title_image" :src="fileImage.dataUrl" />
            </div>
          </div>

        </div>
      </div>
    </div>

    <j-alert
      v-model="msgOpen"
      :type="msgInfo.type"
      :title="msgInfo.title"
      :titleDescription="msgInfo.titleDescription"
      :message="msgInfo.message"
      :button="msgInfo.button"
      :buttonText="msgInfo.buttonText"
      @yes="yes()"
      @cancel="msgOpen = false"
    ></j-alert>

  </j-overlay>
</template>

<script>
import '@/assets/stylus/ui/component/_service.board.modal.styl'

import { mapState, mapActions } from 'vuex'

import * as d3 from 'd3'
import __M from 'moment'
import __C from '@/primitives/_constant_'
import FileServiceMixin from '@/mixins/file.service.mixin'
import StaticOptions from '@/mixins/staticOptions'
import { SafeIdMixin } from '@/mixins/safeid.mixin'
import { ApplicationService } from '@/services'

export default {
  name: 'j-modal-slide-component---board',
  mixins: [
    FileServiceMixin,
    SafeIdMixin,
    StaticOptions
  ],
  props: {
    catCode: null,
    keyValue: null,
    value: null,
  },
  data: () => ({
    // Service Instances -------------
    appService: null,

    classWrapper: 'closed',
    errors: [],
    item: {},
    opened: false,
    progress: {
      complete: 0,
      total: 0
    },
    required: ['title'],

    mode: '',
    msgOpen: false,
    msgInfo: {
      type: "",
      title: "",
      titleDescription: "",
      message: "",
      button: [true, false, true],
      buttonText: ["Yes", "No", "Cancel"]
    },
    yes: () => {},
  }),
  computed: {
    ...mapState(__C.STORE_NAMESPACE.ACCOUNT, ['account']),

    __C_() { return __C },

    catOptions() {
      if(!this.catCode) return []
      return this.reportCatOptions[this.catCode]
    },
    comment() {
      return {
        comment: this.item.content,
        files: this.item.files || [],
        mode: this.mode
      }
    },
    cutoff: {
      get() {
        if(!this.item.cutoff) {
          let weekNames = ['sunday', 'monday', 'tuesday', 'wednesday ', 'thursday', 'friday', 'saturday']
          let weekDay = weekNames.findIndex(n_ => n_ == 'friday')
          let diff_ = weekDay - __M().toDate().getDay()
          let cutoff_ = __M().add(diff_ < 0 ? 7 + diff_ : diff_, 'days').format('YYYY-MM-DD')
          this.item.cutoff = cutoff_
        }

        return this.item.cutoff
      },
      set(val) { 
        let item_ = { ...this.item }
        item_.cutoff = val 

        this.item = item_
      }
    },
    isOwner() { return this.item.createdby == this.account.id },
    modeNew() { return this.mode == __C.FORM.EDIT_MODE_NEW },
    modeMod() { return this.mode == __C.FORM.EDIT_MODE_MOD },
    modeView() { return this.mode == __C.FORM.EDIT_MODE_VIEW },

    fileImage() { 
      if(!this.item.files || this.item.files.length === 0) return null
      return this.item.files.find(f => f.fileType == 'image')
    },
    fileReport() { 
      if(!this.item.files || this.item.files.length === 0) return null
      return this.item.files.find(f => f.fileType != 'image')
    }
  },
  watch: {
    value(val) {
      this.errors = []
      this.item = {}
      this.mode = ''

      if(val) {
        if(!this.keyValue) {
          this.item.catcode = this.catCode
          this.item.createdby = this.account.id
          this.mode = __C.FORM.EDIT_MODE_NEW
        } else {
          this.mode = __C.FORM.EDIT_MODE_VIEW
          this.getBoardReport()
        }
        this.onOpen()
      } else {
        this.onClose()
      }
    }
  },
  created() {
    this.localId = this.id || 'j-board__' + this.safeId('')
    this.appService = new ApplicationService()
  },
  mounted() {
    window.addEventListener('keydown', this.onKeydown)
    setTimeout(() => { d3.select(`#${this.localId}`).select('.j-overlay__scrim').on('click', this.onClose) })
  },
  beforeDestroy () {
    // Remove event listeners before destroying this page.
    window.removeEventListener('keydown', this.onKeydown)
  },
  methods: {
    ...mapActions(__C.STORE_NAMESPACE.APPLICATION, [
      'getBoardReportValues',
      'updateBoardReportValues'
    ]),

    onKeydown(e) {
      if(e.key != 'Escape') return

      this.yes = () => {
        this.msgOpen = false
        this.yes = () => {}
        this.onClose()
      }

      this.msgInfo.type = "INFO"
      this.msgInfo.title = "Cancel & Close"
      this.msgInfo.message = "Do you want to close without saving?"
      this.msgInfo.buttonText[2] = "No"
      this.msgOpen = true
    },
    onOpen() {
      this.opened = true
      setTimeout(() => { 
        this.classWrapper = 'opened'
        d3.select(`#${this.localId}`).select('.content').transition().delay(300).duration(200).style('opacity', 1)
      })
    },
    onClose() {
      if(this.modeMod) {
        this.mode = __C.FORM.EDIT_MODE_VIEW
        return
      }

      d3.select(`#${this.localId}`).select('.content').transition().duration(150).style('opacity', 0)

      setTimeout(() => { 
        this.classWrapper = 'closed'
      }, 150)
      setTimeout(() => { 
        this.opened = false
        this.$emit('input', false) 
      }, 450)
    },
    onDownload(report) {
      if(!this.downloadfile(report.idx, this.account.token)) return

      this.getBoardReportValues(this.catcode).then(values => {
        let recent__ = values.recent || []

        if(recent__.find(r => r.idx == report.idx)) return
        if(recent__.length == 10) recent__.shift()

        recent__.push({ idx: report.idx, name: report.name, date: __M().format('YYYY-MM-DD') })
        values.recent = recent__

        this.updateBoardReportValues({ 
          id: this.catcode,
          values: values
        })
      })
    },
    onEdit() {
      this.mode = __C.FORM.EDIT_MODE_MOD
    },
    onRemoveFile(id) {
      this.appService.delBoardReportFile(id)
    },
    onRemove() {
      this.yes = () => {
        this.msgOpen = false
        this.yes = () => {}
        this.appService.delBoardReport(this.keyValue).then(() => {
          this.onClose()
          this.$emit('saved')
        })
      }

      this.msgInfo.type = "WARN"
      this.msgInfo.title = "Delete the information"
      this.msgInfo.message = "Do you want to delete this report?"
      this.msgOpen = true
    },
    onSaveComment(data) {
      if(!data) return
      
      this.item.content = data.comment
      let item_ = JSON.parse(JSON.stringify(this.item))

      if(item_.idx) item_.idx = Number(item_.idx)
      item_.files = data.files.filter(f => !f.idx).map(f => ({
        name      : f.name,
        mimeType  : f.mimeType,
        fileType  : f.fileType,
        ext       : f.ext,
        dataUrl   : f.dataUrl,
        size      : f.size,
        dimention : f.dimention,
      }))

      let params = new FormData()
      params.append("data", JSON.stringify(item_))
      data.files.forEach(f => { params.append("files", f) })

      let config = {
        onUploadProgress: e => {
          this.progress = {
            complete: e.loaded,
            total: e.total
          }
        }
      }

      let postSave = () => {
        this.progress = { complete: 0, total: 0 }
        this.onClose()
        this.$emit('saved')
      }
      if(this.modeNew) {
        this.appService.putBoardReport(params, config).then(() => { postSave() })
      } else {
        this.appService.updBoardReport(params, config).then(() => { postSave() })
      }
    },
    onSave() {
      if(!this.validateForm()) return
      this.$refs.editor.onSave()
    },

    getBoardReport() {
      this.appService.getBoardReport(this.keyValue).then(res => {
        this.item = res
      })
    },
    errorClass(name) {
      if(this.errors.includes(name)) return ' required'
      return ''
    },
    validateForm() {
      let errors_ = []
      this.required.forEach(name => {
        if(!this.item[name]) errors_.push(name)
      })

      this.errors = errors_
      return errors_.length === 0
    }
  }
}
</script>
